import React from 'react';
import './css/Contact.scss';
import Containerfull from "../fragment/Containerfull";
import Contactus from "../fragment/Contactus";

const Contact = () => {

  return (
    <Containerfull>
      <Contactus />
    </Containerfull>
  );
}

export default Contact;
